import React from "react";
import { graphql } from "gatsby";

import Layout from "../layouts/default";
import SEO from "../utils/seo";
import PostCardGrid from "../components/PostCardGrid";
import PageTitle from "../components/PageTitle";

const BlogPage = ({ data }) => {
  return (
    <Layout>
      <SEO title="Blog" keywords={[`health`, `fitness`, `blog`, `posts`]} />
      <PageTitle image={data.settings.headerImage.asset.fixed} title="Blog" />
      <div className="md:w-3/4 w-full mx-auto px-6 py-12">
        <PostCardGrid posts={data.posts.nodes} />
      </div>
    </Layout>
  );
};

export const queryFragment = graphql`
  fragment BlogPosts on SanityPostConnection {
    nodes {
      title
      slug {
        current
      }
      author {
        name
      }
      topic {
        icon
        name
        color {
          hex
        }
        slug {
          current
        }
      }
      mainImage {
        asset {
          fluid(maxWidth: 700) {
            ...GatsbySanityImageFluid
          }
        }
      }
      _createdAt
    }
  }
`;

export const query = graphql`
  query BlogPageQuery {
    settings: sanitySettings {
      headerImage: pageHeaderBlog {
        asset {
          fixed(width: 1920, height: 300) {
            ...GatsbySanityImageFixed
          }
        }
      }
    }
    posts: allSanityPost {
      ...BlogPosts
    }
  }
`;

export default BlogPage;
