import React from "react";

import PostCard from "./PostCard";

const PostCardGrid = (props) => {
  return (
    <div className="flex flex-row flex-wrap justify-left -mx-3">
      {props.posts.map((post, i) => (
        <div
          key={i}
          className="flex-grow-0 lg:w-1/3 sm:w-1/2 w-full whitespace-normal px-3 mb-6"
        >
          <PostCard post={post} />
        </div>
      ))}
    </div>
  );
};

export default PostCardGrid;
