import React from "react";
import { Link } from "gatsby";
import BackgroundImg from "gatsby-background-image";

import TopicBadge from "./TopicBadge";

const PostCard = (props) => {
  return (
    <div className="transition-standard flex flex-col border border-link">
      <Link to={"/blog/" + props.post.slug.current}>
        {props.post.mainImage ? (
          <BackgroundImg
            className="w-full"
            style={{ height: "300px" }}
            fluid={props.post.mainImage.asset.fluid}
          />
        ) : (
          <div style={{ height: "300px" }} className="bg-gray-300 w-full"></div>
        )}
        <div className="p-6">
          <h2 className="font-serif text-3xl leading-tight">
            {props.post.title}
          </h2>
          <p className="text-base opacity-75 mt-1">
            Authored by
            <span className="text-primary mx-1">{props.post.author.name}</span>
            on
            <span className="text-primary mx-1">
              {new Date(props.post._createdAt).toLocaleDateString(
                {},
                {
                  weekday: "short",
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                }
              )}
            </span>
          </p>
          <object className="mt-3">
            <TopicBadge topic={props.post.topic} />
          </object>
        </div>
      </Link>
    </div>
  );
};

export default PostCard;
